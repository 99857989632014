import React, { useEffect, useState } from "react"
import Seo from "../../components/seo/seo"
import LayoutDiagnostic from "../../components/layoutDiagnostic/layoutDiagnostic"
import StepsDiagnostic from "../../components/stepsDiagnostic/StepsDiagnostic"
import queryString from "query-string"
import { graphql } from "gatsby"
import DiagnosticQuestions from "../../components/diagnosticQuestions/DiagnosticQuestions"
import DiagnosticInfosContact from "../../components/diagnosticInfosContact/DiagnosticInfosContact"
import utils from "../../utils/utils"
import leads from "../../utils/leads"
import { LocalStorage } from "ttl-localstorage"
import diagnostics from "../../utils/diagnostics"
import tracking from "../../utils/tracking"
import "./index.scss"
import links from "../../utils/links"

const pathExlusion = "/inegebilite"
const pathAcceptation = "/acceptation"
const ttl = 3600

export const query = graphql`
  query {
    nodeInfoLeadMentionsLegales {
      field_seo_titre
      field_seo_description
      field_mentions_legales {
        value
      }
      field_sous_titre
      field_titre1
    }
    dataJson {
      additional {
        dcam
        douv
      }
    }
  }
`

const dataStep = [
  { label: "Simulation" },
  { label: "Informations" }
]

const index = ({ data = null }) => {

  const [campagne, setCampagne] = useState("")
  const [isTherecampagne, setIsThereCampagne] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem("campaign") != null){
      setCampagne(localStorage.getItem("campaign"))
      setIsThereCampagne(true)
    }
  }, [])

  const nomenclatures = data ? data.dataJson.additional : null
  const [stepDiag, setStepDiag] = useState(null)
  const [loadLead, setLoadLead] = useState(false)
  const seoPage = {
    title: data.nodeInfoLeadMentionsLegales.field_seo_titre,
    description: data.nodeInfoLeadMentionsLegales.field_seo_description,
  }
  const contactLegal =
      data.nodeInfoLeadMentionsLegales.field_mentions_legales.value
  const titreLegal =
      data.nodeInfoLeadMentionsLegales.field_titre1
  const sousTitreLegal =
      data.nodeInfoLeadMentionsLegales.field_sous_titre
  /***  GET LOCAL STORAGE DIAG */
  const getLocal = utils.getLocalstorageDiag()
  const isStepPossible = utils.isStepDiagPossible()
  const handleCalcul = data => {
    /** CALCULE RESULT */
    const getDataHasValue = isStepPossible.formatData

    const prepareData = leads.prepareData(getDataHasValue, {
      civilite: data.informations.civilite.value === "M" ? true : false,
      prenom: data.informations.firstname.value,
      nom: data.informations.lastname.value,
      tel: utils.detectPhoneCountry(
        data.informations.tel.addValue.data.dial_code,
        data.informations.tel.value
      ),
      cp: data.informations.zip.value,
      email: data.informations.email.value,
      accepte_offre_nexecur: data.informations.recap.value,
      accepte_offre_partenaire: data.informations.consent1.value || false,
      accepte_newsletter: data.informations.consent2.value || false,
    })
    const successPage = () => {
      let formatData = {
        total: 0,
        douv: 0,
        dcam: 0,
        tokenTime: new Date(),
      }
      let access = true
      getDataHasValue.forEach(element => {
        const { value } = element
        if (value.hasOwnProperty("additional")) {
          const additional = value.additional
          if (additional) {
            const valueLabel = additional.type
            const valueAdditional =
              valueLabel === "douv" ? additional.value - 1 : additional.value
            const getOnNommenclature = nomenclatures[valueLabel]
            formatData.total += getOnNommenclature * valueAdditional
            if (formatData.hasOwnProperty(valueLabel))
              formatData[valueLabel] = formatData[valueLabel] + valueAdditional
          }
        }
      })
      /*** REDIRECTION */
      getDataHasValue.forEach(element => {
        if (element.value) {
          if (element.value.vad === false) {
            access = false
          }
        }
      })
      /** REDIRECT PAGE OFFRE */
      if (access) {
        setLoadLead(false)
        /** REDIRECTION */
        localStorage.setItem("diagnostic_total", JSON.stringify(formatData))
        /** STORAGE OPTINS */
        localStorage.setItem(
          "save_diag",
          JSON.stringify({
            accepte_newsletter: prepareData.accepte_newsletter,
            accepte_offre_nexecur: prepareData.accepte_offre_nexecur,
            accepte_offre_partenaire: prepareData.accepte_offre_partenaire,
          })
        )
        /** ADD PARAMS */
        const dataUri = {
          civilite: data.informations.civilite.value,
          firstname: data.informations.firstname.value,
          lastname: data.informations.lastname.value,
          email: data.informations.email.value,
          tel: {
            value: data.informations.tel.value,
            dial_code: data.informations.tel.addValue.data.dial_code,
            code: data.informations.tel.addValue.data.code,
          },
          codetel: data.informations.tel.addValue.data.code,
        }
        LocalStorage.timeoutInSeconds = ttl
        LocalStorage.put("save_info_lead", dataUri, ttl)
        // LocalStorage.put("track_save_diag", window.tc_vars, ttl)
        window.location.href = pathAcceptation + (isTherecampagne ? "/" + campagne : "")
      } else {
        exclusionPage()
      }
    }
    const exclusionPage = () => {
      setLoadLead(false)
      LocalStorage.timeoutInSeconds = ttl
      // LocalStorage.put("track_save_diag", window.tc_vars, ttl)
      window.location.href = pathExlusion + (isTherecampagne ? "/" + campagne : "")
    }
    prepareData["prenom"] = prepareData["prenom"].replace(/\s/g, "-")
    const isExclusion = prepareData.motif_exclusion ? true : false
    leads
      .leadsDiagnostic(prepareData)
      .then(res => {
        if (res.status === 200) {
          if (res.data.status === 0) {
            if (isExclusion) {
              exclusionPage()
            } else {
              successPage()
            }
            return
          }
        }
        exclusionPage()
      })
      .catch(e => {
        exclusionPage()
      })
  }
  const handleSelectPage = index => {
    setStepDiag(index)
  }

  const resetAll = () => {
    localStorage.removeItem("diagnostic")
    setStepDiag(diagnostics.getCorrespondance("questions"))
  }
  /** LEAD */
  const handleResultOffre = data => {
    /*** LOADER */
    if (isStepPossible.access) {
      if (!loadLead) {
        setLoadLead(true)
        handleCalcul(data)
      }
    }
  }

useEffect(() => {
    const { search } = window.location;
    if (search) {
        const queryParams = queryString.parse(search);
        const page = queryParams?.page;
        if (page) {
            /*** AUTRE BIEN */
            if (page === "exclusion") {
                localStorage.setItem(
                    "diagnostic",
                    JSON.stringify({
                        data: [
                            { step: 1, value: "other" },
                            { step: 2, value: "no" },
                            { step: 3, value: "6to7" },
                            { step: 4, value: 1 },
                            { step: 5, value: "no" },
                            { step: 6, value: "no" },
                            { step: 7, value: "yes" },
                            { step: 8, value: "yes" },
                            { step: 9, value: "yes" },
                        ],
                        time: new Date(),
                    })
                );
                setStepDiag(diagnostics.getCorrespondance("informations"));
                return;
            }

            /*** HANDLE NAVIGATION BETWEEN PAGES ***/
            if (page === "questions") {
                setStepDiag(0); // ✅ Allow returning to "questions"
                return;
            }

            if (page === "informations" || page === "contact") {
                const isAccess = isStepPossible.access;
                if (!isAccess) {
                    resetAll();
                    return;
                }
                setStepDiag(1);
                return;
            }
        }
    }

    setStepDiag(0);
}, []); // ✅ Only runs once on mount (fixes infinite loop)

  useEffect(() => {
    try {
      tracking.track("typeTemplate", "diagnosticpage")
      tracking.track("page_name", "diagnostic" + (isTherecampagne ? "/" + campagne : ""))
      tracking.track("categorie", "telesurveillance")
      //tracking.trackInit()
    } catch (e) {
      console.log("Error Track", e)
    }
  }, [])
  return (
    <LayoutDiagnostic isViewPromoOnHeader={true}>
      <Seo
        title={seoPage.title}
        description={seoPage.description}
        linkCanonical={"diagnostic/"}
      />
      <div className="diagnostic-container ">
        <section className="wrapper-diagnostic">
          <div className="substep-navigation b-md-c-w d-f jc-fs pb-4 bb-cg-5 bb-md-n mb-4 pt-md-2 pb-md-2 mb-md-0">
            <StepsDiagnostic
              data={dataStep}
              handleSelect={handleSelectPage}
              index={stepDiag}
            />
          </div>
          <div className="px-md-3 mt-2 ">
            {(stepDiag == 0 || stepDiag === null) && (
              <h1 className="c-1 fs-14 ff-gb lh-24 mb-1">
                Réaliser votre devis alarme
              </h1>
            )}
          </div>
          <noscript>
              <h2 className="titre1">{titreLegal}</h2>
              <h3 className="soustitre">{sousTitreLegal}</h3>
              <div
                className="legal-wording"
                dangerouslySetInnerHTML={{ __html: contactLegal }}
              />
          </noscript>
          {stepDiag == 0 && (
            <DiagnosticQuestions
              getLocal={getLocal}
              handleSelectPage={handleSelectPage}
            />
          )}
          {(stepDiag === 1 ) && (
            <DiagnosticInfosContact
              loadLead={loadLead}
              handleResultOffre={d => handleResultOffre(d)}
              handleSelectPage={handleSelectPage}
              stepName="informations"
            />
          )}
        </section>
      </div>
    </LayoutDiagnostic>
  )
}

export default index
