import React, {
  ReactChildren,
  useRef,
  useState,
  useEffect,
  ReactChild,
} from "react";
import PropTypes from "prop-types";
import Logo from "../logo/logo";
import "./layoutDiagnostic.scss";
import ButtonHelp from "../buttonHelp/buttonHelp";
import BlockCall from "../blockCall/blockCall";
import utils from "../../utils/utils";

import PromoHandband from "../promoHandband/promoHandband";

interface AuxProps {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  isViewPromoOnHeader: Boolean;
  iconRound?: Boolean;
  iconWave?: Boolean;
  dataCall?: any;
  isForcedShow?: Boolean;
  isHeaderSticky?: Boolean;
  getTargetBlock: any;
}

const LayoutDiagnostic = ({
  children,
  isViewPromoOnHeader = false,
  iconRound = true,
  iconWave = false,
  isForcedShow = false,
  isHeaderSticky = false,
  getTargetBlock = null,
}: AuxProps) => {
  const targetBlock = useRef(null);
  const [openCall, setOpenCall] = useState(false);
  const [isPromoActive, setIsPromoActive] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0); // Initialize with a default value

  const handleClick = (bool = !openCall) => {
    setOpenCall(bool);
    utils.lockScrollWinodws(bool);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWindowWidth(window.innerWidth); // Set window width only in the browser

      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  const isMobile = windowWidth < 768;

  /** SET TARGET BLOCK FOR PARENT */
  useEffect(() => {
    if (targetBlock) {
      if (targetBlock.current && getTargetBlock) {
        getTargetBlock(targetBlock.current);
      }
    }
  }, [targetBlock]);

  return (
    <main>
      <BlockCall handleClick={handleClick} openCall={openCall} />
      <div className="layout-diagnostic">
        {iconWave && (
          <span className="icon-wave wave-decoration d-b d-md-n"></span>
        )}
        {iconRound && <span className="item-decoration d-b d-md-n"></span>}
        <div
          ref={targetBlock}
          className={
            isHeaderSticky
              ? isPromoActive
                ? " is-sticky is-promo header-layout-diagnoctic w-100"
                : " is-sticky header-layout-diagnoctic w-100"
              : isPromoActive
              ? " header-layout-diagnoctic w-100 is-promo"
              : " header-layout-diagnoctic w-100"
          }
        >
          <div className="content w-100">
            <div className="w-100 bc-w p-r bb-md-cg-1 ">
              <div className="wrapper">
                <div className="d-f jc-sb ai-c">
                  <div className="py-1">
                    <Logo />
                  </div>
                  <ButtonHelp
                    addClassWording="d-md-n"
                    addClass="d-f mr-2 bc-1 b-md-c-2 c-w btn-help "
                    logo="icon-phone-white mr-1 mr-md-0"
                    handleClick={handleClick}
                    isMobile={true}
                  />
                </div>
              </div>
            </div>
            <div className="promo-handband-wrapper-diagnostic">
              {/* Wrap PromoHandband in a container */}
              <PromoHandband
                isPromoActive={isPromoActive}
                isViewPromoOnHeader={isViewPromoOnHeader}
                setIsPromoActive={setIsPromoActive}
                isDiagnosticPage={true}
              />
            </div>
          </div>
        </div>
        <div
          className={`container-calc-header bcg-2 page-principale-diag ${
            !isPromoActive ? "no-handband" : ""
          }`}
        >
          {children}
        </div>
      </div>
    </main>
  );
};

LayoutDiagnostic.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutDiagnostic;
