import React, { useEffect, useState } from "react"
import queryString from "query-string"
import LayoutDiagnostic from "../layoutDiagnostic/layoutDiagnostic"
import Seo from "../seo/seo"
import StepsDiagnostic from "../stepsDiagnostic/StepsDiagnostic"
import QuestionDianostic from "../questionDiagnostic/questionDianostic"
import QuestionPagination from "../questionPagination/QuestionPagination"
import { useStaticQuery, graphql } from "gatsby"
import { questionData } from "../../models/diagnostic"
import NavigationDiagnostic from "../navigationDiagnostic/NavigationDiagnostic"
import Moment from "moment"
import "./index.scss"
import utils from "../../utils/utils"
import { getQuestionDiag } from "../../hooks/hooks"
import { getIconsPedagocyDiag } from "../../hooks/hooks"
import tracking from "../../utils/tracking"
import links from "../../utils/links"


const limitInputNumber = 3
const basePage = "/diagnostic/?page=questions"
const pathExlusion = "/inegebilite"

type nodesArray = Array<{
  step: Number
  properties: Object
  id: String
  label: String
  pedagogy: null | Object
}>

type nodesArrayImage = Array<{
  id: String
  fluid: {
    originalImg: String
    originalName: String
  }
}>

interface Props {
  data?: any
  getLocal: {
    result: any
    access: Boolean
  }
  handleSelectPage: (e: Number) => any
}

let specialCase = false

const DiagnosticQuestions = ({ handleSelectPage, getLocal }: Props) => {
  const questions = getQuestionDiag()
  const iconsPedagocyStatic = getIconsPedagocyDiag()
  const [formQuestion, setFormQuestion] = useState(null)
  const [error, setErorr] = useState("")
  const [stepQuestion, setStepQuestion] = useState(1)
  const [formatList, setFormatList] = useState([])
  const [domQuestion, setDomQuestion] = useState(null)
  const [domPagination, setDomPagination] = useState(null)
  const [isStart, setIsStart] = useState(false)
  const isJsonFile = true
  const [campagne, setCampagne] = useState("")
  const [campagneLoad, setCampagneLoad] = useState(false)
  const [isTherecampagne, setIsThereCampagne] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined'){
      if (localStorage.getItem("campaign") != null){
        setCampagne(localStorage.getItem("campaign"))
        setIsThereCampagne(true)
      }
      setCampagneLoad(true)
    }

  }, [])

    const dataGraphQl = useStaticQuery(graphql`
        query {
          nodeInfoLeadMentionsLegales {
            field_mentions_legales {
              value
            }
            field_afficher_texte_complementa
            field_sous_titre
            field_titre1
          }
        }
      `)

    const afficherTexteComplementaire =
      dataGraphQl.nodeInfoLeadMentionsLegales.field_afficher_texte_complementa
    const contactLegal =
    dataGraphQl.nodeInfoLeadMentionsLegales.field_mentions_legales.value
    const titreLegal =
      dataGraphQl.nodeInfoLeadMentionsLegales.field_titre1
    const sousTitreLegal =
      dataGraphQl.nodeInfoLeadMentionsLegales.field_sous_titre

    const [hide, setHide] = useState(false)
    const [scrollTop, setScrollTop] = useState(false)
    const [temp, setTemp] = useState(false)

    useEffect(() => {
      if (!hide && temp) {
        setScrollTop(true)
        setTemp(false)
        setTimeout(() => {
          setScrollTop(false)
        }, 500)
      }
    }, [hide])

  const setLocal = data => {
    let dataLocal = []
    if (data) {
      if (data[0].value.value != "other") {
        data.forEach(element => {
          if (element.value) {
            dataLocal.push({ step: element.step, value: element.value.value })
          }
        })
      } else {
        dataLocal.push({ step: 1, value: "other" })
        dataLocal.push({ step: 2, value: "no" })
        dataLocal.push({ step: 3, value: "1to3" })
        dataLocal.push({ step: 4, value: 1 })
        dataLocal.push({ step: 5, value: "no" })
        dataLocal.push({ step: 6, value: "no" })
        dataLocal.push({ step: 7, value: "no" })
        dataLocal.push({ step: 8, value: "no" })
        dataLocal.push({ step: 9, value: "no" })
        specialCase = true
      }
    }
    if (dataLocal.length > 0) {
      localStorage.setItem(
        "diagnostic",
        JSON.stringify({ time: new Date(), data: dataLocal })
      )
    } else {
      localStorage.removeItem("diagnostic")
    }
  }

  const getParams = () => {
    const { search } = window.location
    if (search) {
      const queryParams = queryString.parse(search)
      const { step, value } = queryParams
      return { step: step, value: value }
    }
    return { step: undefined, value: undefined }
  }

  /**
 * Compare LocalStorage et Questions
 * Return Null or Data
 * @param questions
 * @returns
 */
  const removeLocalRedirect = () => {
    localStorage.removeItem("diagnostic")
    window.location.href = basePage + (isTherecampagne ? "/" + campagne : "")
  }

  /** Functions */

  const setParams = (step, value, label = "") => {
    let query = ""
    query += step ? `?page=questions&step=${step}` : ""
    query += value ? `&value=${value}` : ""
    query += campagne.replace("?","&")
    window.history.replaceState({}, "", window.location.pathname + query)
  }
  const getInformationByStep = (step, property) => {
    const _dataByStep = questions.filter(e => e.step == step)
    if (_dataByStep) {
      return _dataByStep[0][property]
    }
    return ""
  }
  const updateFormQuestion = (
    getStep: any,
    getValue: any,
    isChangeNav: boolean = true,
    currentQuestion = null
  ) => {
    const saveQuestion = currentQuestion ? currentQuestion : formQuestion
    let blackList = []

    saveQuestion.forEach(element => {
      if (element.step == getStep && getValue) {
        element.prevValue = getValue
      }
      const { prevValue, isVisible } = element
      /*** VALUE */
      if (isVisible && prevValue) {
        if (prevValue.exclude) {
          prevValue.exclude.forEach(e => blackList.push(e))
        }
      }
    })
    /** Check On BlackList */
    const questionsBlackList = saveQuestion.filter(
      e => !blackList.includes(e.step)
    )
    questionsBlackList.forEach((element, index) => {
      const { prevValue } = element
      const stepElement = element.step
      const nextStep = index + 1 < questionsBlackList.length ? index + 1 : index
      const prevStep = index - 1
      const indexQuestion = saveQuestion.findIndex(e => e.step === stepElement)
      saveQuestion[indexQuestion].prevStep =
        prevStep === -1 ? false : questionsBlackList[prevStep].step

      if (prevValue) {
        saveQuestion[indexQuestion].nextStep = questionsBlackList[nextStep].step
      }
    })
    setFormQuestion([...saveQuestion])
    setErorr("")
    /** URL */
    setParams(
      getStep,
      getValue ? getValue.value : null,
      getValue ? getValue?.label : ""
    )
    /*** DOM */
    /** TRACK */
    return saveQuestion
  }

  const dataFormatedVisible = (stepProps, questionData) => {
    let blackList = []
    let isContinu = true
    const saveQuestion = questionData
    saveQuestion.forEach((element, index) => {
      const currentElement = stepProps == element.step
      const value = element.prevValue
      if (value) {
        if (value.hasOwnProperty("exclude")) {
          const getExclude = value.exclude
          if (getExclude)
            getExclude.forEach(element => {
              if (!blackList.includes(element)) blackList.push(element)
            })
        }
        element.value = value
        if (value.isContinu === false) {
          isContinu = false
        }
      }
      /*** END CHECK */
      element.isActive = currentElement ? true : false
      element.isVisible = true
    })
    /*** HARD REDIRECTION */
    if (!isContinu) {
      window.location.href = pathExlusion + (isTherecampagne ? "/" + campagne : "")
      return null
    }

    /** DELETE QUESTIONS BLACKLIST */
    if (blackList) {
      blackList.forEach(el => {
        const indexElementBlackList = saveQuestion.findIndex(e => e.step === el)
        if (indexElementBlackList !== -1) {
          saveQuestion[indexElementBlackList].isVisible = false
          saveQuestion[indexElementBlackList].value = null
          saveQuestion[indexElementBlackList].prevValue = null
        }
      })
    }
    /** DELETE DATA IF CHANGE VALUE FIRST */
    saveQuestion.forEach((element, index) => {
      if (index + 1 === saveQuestion.length) {
        element.value = stepQuestion !== element.step ? null : element.value
        element.prevValue =
          stepQuestion !== element.step ? null : element.prevValue
      }
      if (stepProps <= element.step && index + 1 !== saveQuestion.length) {
        if (isStart) {
          element.prevValue = null
          element.value = null
        }
      }
    })
    return saveQuestion
  }

  const updateStepQuestion = (stepProps, currentQuestions = null) => {
    if (stepProps) {
      setErorr("")
      const questionSave = currentQuestions ? currentQuestions : formQuestion
      const saveQuestion = dataFormatedVisible(stepProps, questionSave)
      /** TRACKING LAST STEP */
      if (
        saveQuestion[saveQuestion.length - 1].step === stepProps &&
        saveQuestion[saveQuestion.length - 1].value
      ) {
        trackingByQuestion(true)
      }
      /** BEHAVIOR ON QUESTION */
      setStepQuestion(stepProps)
      setFormQuestion([...saveQuestion])
      setFormatList([...saveQuestion.filter(e => e.isVisible)])
      handleResult(saveQuestion)
      /** Redirection URL */
      const getActive = saveQuestion.filter(e => e.isActive)
      if (getActive.length > 0) {
        const { step, value } = getActive[0]
        const formatValue = value ? `&value=${value.value}` : ""
        setParams(step, formatValue)
      }
      /** LocalStorage */
      setLocal(saveQuestion.filter(e => e.isVisible))
      if (specialCase) {
        handleSelectPage(2)
      }
      return
    } else {
      setErorr("Vous devez sélectionner un choix pour passer à la suite ")
    }
  }

  const handleResult = questions => {
    const getActive = questions.filter(
      e => e.isActive && e.nextStep === e.step && e.value
    )
    if (getActive.length > 0) {
      handleSelectPage(1)
    }
  }

  const selectQuestionByStep = (step, questions = null) => {
    const getQuestions = formQuestion ? formQuestion : questions
    let formatValue = null
    let label = ""
    if (getQuestions) {
      getQuestions.forEach(element => {
        if (element.step === step) {
          element.isActive = true
          formatValue = element.value ? element.value.value : null
        } else {
          element.isActive = false
        }
        if (element.prevValue) {
          element.prevValue = element.value
        }
      })
    }

    setErorr("")
    setStepQuestion(step)
    setFormQuestion([...getQuestions])
    setFormatList([...getQuestions.filter(e => e.isVisible)])
    setParams(step, formatValue)
  }

  const windowScrollTo = value => {
    window.scrollTo({
      top: value,
      behavior: "smooth",
    })
  }

  const startDiagnostic = (queryStep, queryValue = null) => {
    let dataByValue = null
    let formatStep: String | Number = queryStep // CHNAGE BY QueryStep
    let getTransformLocal = getLocal.result
    let isReset = false
    if (getLocal.result?.data[0].value === "other") {
      isReset = true
    }
    /**, Check QueryStep Exist */
    try {
      const queryToInt = parseInt(queryStep)
      if (queryToInt !== null) {
        const isStepExist = questions.filter(el => el.step === queryToInt)
        /** REDIRECT */
        if (isStepExist.length === 0) {
          // removeLocalRedirect()
          //return
          isReset = true
        } else {
          formatStep = queryToInt
        }
      }
    } catch (e) {
      console.log(e)
      isReset = true
    }
    /** EXEPTION STEP 1 */
    if (getTransformLocal && queryValue) {
      const { data } = getTransformLocal
      if (data) {
        const step1 = data.filter(e => e.step === 1)
        if (step1.length > 0) {
          if (formatStep === 1 && queryValue !== step1[0].value) {
            getTransformLocal = null
            localStorage.removeItem("diagnostic")
            setLocal([
              {
                step: 1,
                value: { value: queryValue },
              },
            ])
          }
        }
      }
    } else {
      if (formatStep === 1 && queryValue) {
        setLocal([
          {
            step: 1,
            value: { value: queryValue },
          },
        ])
      }
    }
    const questionsFormatBrut = questions.map(el => {
      /** STATIC IMAGE ON JSON */
      const getPedagoImage = el.pedagogy
        ? iconsPedagocyStatic
          ? iconsPedagocyStatic.filter(
              e => e.fluid.originalName === el.pedagogy.icon
            )
          : []
        : []
      const pedagoFormat =
        isJsonFile && el.pedagogy
          ? {
              ...el.pedagogy,
              icon: getPedagoImage.length
                ? getPedagoImage[0].fluid.originalImg
                : "",
            }
          : el.pedagogy
      /** QUERY PARAMS STEP && VALUE */
      const currentStep = el.step
      const formatValue = () => {
        /** GET VALUE CURRENT STEP PARAMS */
        if (currentStep == formatStep && queryValue) {
          /** CHECK NUMBER TYPE */
          const isNumberType = el.properties.type === "number"
          if (!isNumberType) {
            const dataFilterByValue = el.properties.data.filter(
              e => e.value === queryValue
            )
            if (dataFilterByValue) {
              dataByValue = dataFilterByValue[0]
              return dataFilterByValue[0]
            }
          }
        }
        if (getTransformLocal) {
          const getValueByStep = getTransformLocal.data.filter(
            dataLocal => dataLocal.step == currentStep
          )
          if (getValueByStep.length > 0) {
            const localValue = getValueByStep[0].value
            if (localValue) {
              if (el.properties.data.length > 0) {
                const filterDatabyValue = el.properties.data.filter(
                  e => e.value === localValue
                )
                if (filterDatabyValue) {
                  return filterDatabyValue[0]
                }
              } else if (el.properties.type === "number") {
                const parseValueInt = parseInt(localValue)

                if (typeof parseValueInt == "number") {
                  const isVad = parseValueInt > limitInputNumber ? false : true
                  return { value: parseValueInt, label: localValue, vad: isVad }
                }
              }
            }
          }
        }
        return null
      }

      return {
        ...el,
        value: formatValue(),
        prevValue: formatValue(),
        nextStep: false,
        prevStep: false,
        isVisible: true,
        isActive: el.step == formatStep ? true : false,
        pedagogy: pedagoFormat,
      }
    })
    const questionsFormat = dataFormatedVisible(formatStep, questionsFormatBrut)
    const questionsVisible = questionsFormatBrut.filter(e => e.isVisible)
    const stepPropsIsBlackList = questionsFormatBrut.filter(
      e => e.step === formatStep && !e.isVisible
    )
    const getQuestionsHaveValue = questionsVisible
      .filter(e => e.value !== null)
      .map(e => e.step)
    const getLastStepWithValue =
      getQuestionsHaveValue.length > 0
        ? getQuestionsHaveValue[getQuestionsHaveValue.length - 1]
        : 2
    const questionPosibility = questionsFormat.filter(e => e.isVisible)
    /** CHECK IS POSSIBLE GO TO QUESTION REFRESH */
    if (
      questionPosibility.filter(e => e.step == parseInt(queryStep)).length === 0
    ) {
      isReset = true
    } else {
      if (
        questionPosibility.length > 0 &&
        getLastStepWithValue &&
        queryStep !== "1"
      ) {
        const stepPossibility = questionPosibility.map(e => e.step)
        if (stepPossibility) {
          let indexBeforeQuestion: Number | null = null
          stepPossibility.forEach((element, index) => {
            if (queryStep == element) {
              indexBeforeQuestion = index - 1
            }
          })
          /** Question Before Possibility */
          if (indexBeforeQuestion !== null) {
            const valueBeforeQuestion =
              questionPosibility[indexBeforeQuestion].value
            if (!valueBeforeQuestion) {
              isReset = true
            }
          }
        }
      }
    }
    /** FILL LAST QUESTION */

    return {
      isReset: isReset,
      questionsFormat: questionsFormat,
      dataByValue: dataByValue,
      stepResponse: formatStep,
    }
  }
  const trackingByQuestion = (isLastStep = false) => {
    if (formQuestion) {
      const _currentStep = stepQuestion
      const dataStep = formQuestion
        .filter(e => e.step <= _currentStep)
        .map(el => (el.value !== null ? el.value?.value.toString() : "not set"))
      let diagData = [!isLastStep ? _currentStep : _currentStep + 1].concat(
        dataStep
      )
      if (diagData.length > 0) {
        diagData[0] = diagData[0].toString()
      }

      tracking.trackDiagInfo(
        "page.display",
        "/diagnostic/questions/" + _currentStep,
        diagData,
        isLastStep ? "diagnostic_questions" : null
      )
    }
  }
  /** Init List Question */
  useEffect(() => {
    /** QUERY PARAMS */
    if (questions && campagneLoad) {
      const queryStep: any = getParams().step ? getParams().step : "1"
      const queryValue = getParams().value ? getParams().value : ""
      /** Check Step Exist */
      const stepPossible = questions.map(el => el.step)
      const isQueryStepPossible = stepPossible.filter(el => queryStep == el)

      /** V3 */
      if (isQueryStepPossible.length) {
        const { isReset, questionsFormat, dataByValue, stepResponse } =
          startDiagnostic(queryStep, queryValue)

        /** CHECK STEP POSSIBILITY */
        if (isReset) {
          removeLocalRedirect()
          //return
        }

        updateFormQuestion(stepResponse, dataByValue, false, questionsFormat)
        selectQuestionByStep(stepResponse, questionsFormat)
        setIsStart(true)
      } else {
        removeLocalRedirect()
      }
    }
  }, [campagneLoad])
  useEffect(() => {
    if (campagneLoad){
      trackingByQuestion()
    }
  }, [formatList, campagneLoad])
  return (
  <div className="container-dia-question d-f fd-r fd-lg-c ai-fs">
    {/** NAVIGATION */}
    <NavigationDiagnostic
      formQuestion={formQuestion}
      formatList={formatList}
      selectStepQuestionByNav={selectQuestionByStep}
    />
    {formatList && formQuestion && (
      <>
        <div className="diagnostic-column">
          {/** QUESTION CONTENT */}
          <div className="wrapper-diagnostic-question">
            <QuestionDianostic
              setDomQuestion={setDomQuestion}
              error={error}
              updateFormQuestion={updateFormQuestion}
              formQuestion={formatList}
            />
            <QuestionPagination
              selectQuestionByStep={selectQuestionByStep}
              setDomPagination={setDomPagination}
              selectStepQuestion={updateStepQuestion}
              formQuestion={formQuestion}
            />
          </div>

          {/** LEGAL TEXT SECTION */}
          {afficherTexteComplementaire && (
            <div className={`block-legal-hide ${hide ? "is-active" : ""}`}>
              <p className="titre1">{titreLegal}</p>
              <p className="soustitre">{sousTitreLegal}</p>
              <div
                dangerouslySetInnerHTML={{ __html: contactLegal }}
                className="legal-wording"
              ></div>
            </div>
          )}

          {/** LEGAL TEXT TOGGLE BUTTON */}
          {afficherTexteComplementaire && (
            <div className="divbutton">
              <button
                onClick={() => {
                  setTemp(hide);
                  setHide(!hide);
                }}
                className={
                  hide
                    ? "btn-show-more btn-select-round c-p d-f fd-r ai-c is-active"
                    : "btn-show-more btn-select-round c-p d-f fd-r ai-c"
                }
                style={!hide ? { backgroundColor: "white" } : {}}
                ref={(el) => {
                  if (el) {
                    if (scrollTop) {
                      setTimeout(() => {
                        el.scrollIntoView({
                          behavior: "smooth",
                          block: "end",
                        });
                      }, 250);
                    }
                  }
                }}
              >
                {hide ? "En savoir moins" : "En savoir plus"}
                <span className="icon-arrow-down-c1 ml-1 arrow" />
              </button>
            </div>
          )}
        </div>
      </>
    )}
  </div>

  )
}

export default DiagnosticQuestions
